var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mt-3 mobile",style:(_vm.criandoSeguro ? 'position:fixed;' : '')},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-3"},[_c('div',[_c('div',{staticClass:"texto-superio"},[_vm._v("Seguro acidentes pessoais")]),_c('div',{staticClass:"font-size-infor mb-3 text-justify"}),_c('b-alert',{staticClass:"mt-3 font-size-infor text-justify",attrs:{"show":"","variant":"info"}},[_c('strong',[_vm._v("Após fazer o upgrade, a nova cobertura de acidente pessoal entrará em vigor na sua próxima apólice com a renovação em "+_vm._s(_vm.formatacaoData.formatarDataLocal(_vm.dataVencimento))+".")]),_vm._v(" Até lá você não será cobrado(a) por isso.")]),_c('div',{staticClass:"font-size-infor mb-4"},[_vm._v(" Escolha abaixo a cobertura que mais te atende. É fácil, rápido e cabe no seu bolso! ")]),_c('div',{staticClass:"row d-flex justify-content-center"},_vm._l((_vm.lstPlanosOpcional),function(plano,index){return _c('div',{key:'planoOpcional' + index,staticClass:"mb-3 box-shadow col-xl-4 col-lg-12"},[_c('div',{class:plano.id == _vm.config.ID_PLANO_OURO
                    ? 'card card-body-plano border-success'
                    : 'card card-body-plano ',style:(_vm.planoAnual == false ? 'min-height:310px' : '')},[(plano.id == _vm.config.ID_PLANO_OURO)?_c('div',[_c('div',{staticClass:"badge badge-pill badge-success"},[_vm._v(" Recomendado ")])]):_vm._e(),_c('div',{staticClass:"card-body"},[_c('h5',[_vm._v(_vm._s(plano.id))]),(plano.id == _vm.config.ID_PLANO_OURO)?_c('div',{staticClass:"idenizacao mb-1"},[_c('strong',[_vm._v(" Indenização: "+_vm._s(_vm.config.DESCRICAO_PLANO_AP_OURO_IDENIZACAO))]),_c('div',{staticClass:"mb-1"},[_vm._v("Aplicada no caso de:")])]):_vm._e(),(plano.id == _vm.config.ID_PLANO_PRATA)?_c('div',{staticClass:"idenizacao mb-1"},[_c('strong',[_vm._v(" Indenização: "+_vm._s(_vm.config.DESCRICAO_PLANO_AP_PRATA_IDENIZACAO))]),_c('div',{staticClass:"mb-1"},[_vm._v("Aplicada no caso de:")])]):_vm._e(),(plano.id == _vm.config.ID_PLANO_OURO)?_c('ul',{staticClass:"plano-detalhes"},_vm._l((_vm.config.DESCRICAO_PLANO_AP_OURO),function(item,index){return _c('li',{key:'u' + index},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e(),(plano.id == _vm.config.ID_PLANO_PRATA)?_c('ul',{staticClass:"plano-detalhes"},_vm._l((_vm.config.DESCRICAO_PLANO_AP_PRATA),function(item,index){return _c('li',{key:'u' + index},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"card-footer-desktop"},[_c('div',{staticClass:"text-center text-muted"},[_c('h2',{staticClass:"card-title pricing-card-title",staticStyle:{"color":"#23ad21"}},[_vm._v(" "+_vm._s(_vm.formatacaoMoeda( _vm.planoAnual ? plano.valorAnualComAssistencia24h : plano.valorMensalComAssistencia24h ))+" "),(_vm.planoAnual)?_c('small',{staticClass:"text-muted"},[_vm._v("/ ano")]):_c('small',{staticClass:"text-muted"},[_vm._v("/ mês")])])]),_c('button',{staticClass:"btn btn-block-desktop btn-success",attrs:{"disabled":_vm.criandoSeguro},on:{"click":function($event){return _vm.criarNovoSeguro(
                        plano.id,
                        plano.valorMensalComAssistencia24h
                      )}}},[_vm._v(" Contratar ")])])])])}),0),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-12"},[_c('b-button',{staticClass:"btn-block",attrs:{"variant":"outline-success","to":"/cliente"}},[_vm._v(" Voltar para o painel ")])],1)])],1)])]),(_vm.criandoSeguro)?_c('Loading',{attrs:{"criandoSeguro":_vm.criandoSeguro}}):_vm._e()],1),_c('div',{staticClass:"row desktop"},[_vm._m(1),_c('div',{staticClass:"col-9 layout-desktop"},[_c('div',[_c('div',{staticClass:"row mt-5 card card-body",staticStyle:{"border-radius":"20px"}},[_c('div',[_vm._m(2),_c('div',{staticClass:"row d-flex justify-content-center"},_vm._l((_vm.lstPlanosOpcional),function(plano,index){return _c('div',{key:'planoOpcional' + index,staticClass:"mb-3 box-shadow col-xl-4 col-lg-12"},[_c('div',{class:plano.id == _vm.config.ID_PLANO_OURO
                      ? 'card card-body-plano-desktop plano-recomendado-desktop border-success'
                      : 'card card-body-plano-desktop plano-nao-recomendado-desktop'},[(plano.id == _vm.config.ID_PLANO_OURO)?_c('div',[_c('div',{staticClass:"badge badge-pill badge-success-desktop"},[_vm._v(" Recomendado ")])]):_vm._e(),_c('div',{staticClass:"card-body"},[_c('strong',{staticClass:"card-title-desktop"},[_vm._v(_vm._s(plano.id))]),(plano.id == _vm.config.ID_PLANO_OURO)?_c('div',{staticClass:"idenizacao mb-1"},[_c('strong',[_vm._v(" Indenização: "+_vm._s(_vm.config.DESCRICAO_PLANO_AP_OURO_IDENIZACAO))]),_c('div',{staticClass:"mb-1"},[_vm._v("Aplicada no caso de:")])]):_c('div',{staticClass:"idenizacao mb-1"},[_c('strong',[_vm._v("Indenização: "+_vm._s(_vm.config.DESCRICAO_PLANO_AP_PRATA_IDENIZACAO)+" ")]),_c('div',{staticClass:"mb-1"},[_vm._v("Aplicada no caso de:")])]),(plano.id == _vm.config.ID_PLANO_OURO)?_c('ul',{staticClass:"texto-card-desktop",staticStyle:{"margin-bottom":"0px","margin-left":"-22px"}},_vm._l((_vm.config.DESCRICAO_PLANO_AP_OURO),function(item,index){return _c('li',{key:'u' + index},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e(),(plano.id == _vm.config.ID_PLANO_PRATA)?_c('ul',{staticClass:"texto-card-desktop",staticStyle:{"margin-bottom":"0px","margin-left":"-22px"}},_vm._l((_vm.config.DESCRICAO_PLANO_AP_PRATA),function(item,index){return _c('li',{key:'u' + index},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"card-footer-desktop"},[_c('div',{staticClass:"text-center text-muted"},[_c('h4',{staticClass:"pricing-card-title-desktop",staticStyle:{"color":"#23ad21"}},[_vm._v(" "+_vm._s(_vm.formatacaoMoeda(plano.valorMensalComAssistencia24h))+" "),_c('small',{staticClass:"text-muted"},[_vm._v("/ mês")])])]),_c('button',{staticClass:"btn btn-block-desktop btn-success",attrs:{"disabled":_vm.criandoSeguro},on:{"click":function($event){return _vm.criarNovoSeguro(
                          plano.id,
                          plano.valorMensalComAssistencia24h
                        )}}},[_vm._v(" Contratar ")])])])])}),0)]),_c('b-alert',{staticClass:"mt-3",attrs:{"show":"","variant":"info"}},[_c('strong',[_vm._v("Após fazer o upgrade, a nova cobertura de acidente pessoal entrará em vigor na sua próxima apólice com a renovação em "+_vm._s(_vm.formatacaoData.formatarDataLocal(_vm.dataVencimento))+".")]),_vm._v(" Até lá você não será cobrado(a) por isso.")])],1),_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-button',{staticClass:"sucesso-botao-desktop",attrs:{"variant":"outline-success","to":"/cliente"}},[_vm._v(" Voltar para o painel ")])],1)])])])])]),_c('div',[(_vm.criandoSeguro)?_c('Loading',{attrs:{"criandoSeguro":_vm.criandoSeguro}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 col-sm-2 box-line-miguel"},[_c('img',{staticClass:"mt-4",attrs:{"src":"/img/Untitled-1.svg","height":"65"}})]),_c('div',{staticClass:"miguelSuperior col-9 col-sm-10 text-justify"},[_c('div',[_vm._v(" Com a cobertura para acidentes pessoais específica para ciclistas, você e sua bike estão protegidos para pedalar com a segurança que você merece! "),_c('p'),_vm._v(" Escolha abaixo a cobertura que mais te atende. É fácil, rápido e cabe no seu bolso! "),_c('p')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3 retangulo-desktop"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 miguel-desktop"},[_c('img',{staticClass:"img-miguel-desktop",attrs:{"src":"/img/Untitled-1.svg"}})]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"bem-vindo-desktop"},[_c('div',[_vm._v(" Nós pensamos em tudo! E agora além de proteger a sua bike, queremos proteger o mais importante, você ciclista. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-size-infor-desktop-planos"},[_vm._v(" Com a cobertura para acidentes pessoais específica para ciclistas, você e sua bike estão protegidos para pedalar com a segurança que você merece! "),_c('p'),_vm._v(" Escolha abaixo a cobertura que mais te atende. É fácil, rápido e cabe no seu bolso! ")])
}]

export { render, staticRenderFns }